<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Debug Playground</h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <button class="btn btn-primary btn-sm" @click="get_url">anu</button>
            {{ var_coba }}
            <br />
            <button class="btn btn-primary btn-sm" @click="get_data">inu</button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- modals -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import CryptoService from "@/core/services/crypto.service";
import axios from "axios";

export default {
  data() {
    return {
      var_coba: "",
    };
  },
  computed: {
    ...mapGetters(["listBookTicker", "currentUser"]),
  },
  components: {},
  methods: {
    get_url() {
      let timestamp = Date.now();
      let recvWindow = 15000;
      let qstring = "timestamp=" + timestamp + "&recvWindow=" + recvWindow;
      let signature = CryptoService.hmacsha256("mwaNPyyeYGFdKjyvyoKKtSGUeB6jKvG6uQivOyg0NMS2b919A8QcW3e2sc8GELyZ", qstring);
      qstring = qstring + "&signature=" + signature;
      this.var_coba = "https://api.binance.com/api/v3/account?" + qstring;
    },
    get_data() {
      axios
        .get(this.var_coba, {
          headers: {
            "X-MBX-APIKEY": "pN5LOgkKnHe9q5BuMb8OtldGpQb3hTBj5NvUtA1FPPEa5bx7VpnLEa8VIHK3tWjz",
          },
        })
        .then(res => {
          console.log(res.data);
        });
    },
  },
  mounted() {
    if (!(this.currentUser.level_akses == 1 || this.currentUser.fitur.find(i => i == "debug") != undefined)) {
      this.$router.push({ name: "setting" });
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Market Watch" }, { title: "Debug Playground! jgn di utik2" }]);
  },
};
</script>
